<template>
    <div class="fl-x fl-x-cc">
        <div class="">

            <div v-if="pagination_sec_count >= 1 && perfect_pagination">
                <div v-if="curr_pagination_sec>1" class="d-inline cursor-pointer font-inter-medium mr-2"
                     @click="previousPaginationSection">Previous
                </div>

                <template>
                    <btn design="basic-b" shadow="0" color="secondary" border-radius="0" size="sm"
                         class="border-none mr-1 mr-lg-2 pagination-btn"
                         :class="{'active-page':current_page === i + (curr_pagination_sec - 1) * 5 }"
                         v-for="i in ( curr_pagination_sec *5 - (curr_pagination_sec -1) * 5 )"
                         @click="gotoPage(i + (curr_pagination_sec - 1) * 5)" :key="i">
                        {{ i + (curr_pagination_sec - 1) * 5 + 'cc' }}
                    </btn>
                </template>

                <div class="d-inline cursor-pointer font-inter-medium ml-2" @click="nextPaginationSection">Next</div>

            </div>

            <div v-else-if="pagination_sec_count >= 1 && !perfect_pagination">

                <!--                <btn v-if="curr_pagination_sec >1" class="fa fa-chevron-left mr-1 mr-lg-2"-->
                <!--                     @click="previousPaginationSection" size="sm"/>-->
                <div v-if="curr_pagination_sec >1" class="d-inline cursor-pointer font-lato-medium mr-2"
                     @click="previousPaginationSection">Previous
                </div>

                <template v-if="notEnoughPages">

                    <btn class="mr-1 mr-lg-2 border-none" size="sm"
                         :class="{'active-page':current_page === i }" design="basic-b"
                         v-for="i in lastPage"
                         @click="gotoPage(i)" :key="i">
                        {{ i }}
                    </btn>

                </template>

                <template v-else-if="curr_pagination_sec > pagination_sec_count ">
                    <btn class="mr-1 mr-lg-2 border-none" size="sm" border-radius="0"
                         :class="{'active-page':current_page === i + (curr_pagination_sec - 1) * 5 }" design="basic-b"
                         v-for="i in ( last_page - (curr_pagination_sec -1) * 5 )"
                         @click="gotoPage(i + (curr_pagination_sec - 1) * 5)" :key="i">
<!--                        {{ i + (curr_pagination_sec - 1) * 5 + 'cc' }}-->
                        {{ i + (curr_pagination_sec - 1) * 5 }}
                    </btn>
                </template>

                <template v-else>

                    <btn class="mr-1 mr-lg-2 border-none" size="sm" border-radius="0"
                         :class="{'active-page':current_page === i + (curr_pagination_sec - 1) * 5 }" design="basic-b"
                         v-for="i in stripPages"
                         @click="gotoPage(i)" :key="i">
                        {{ i }}
                    </btn>

                </template>

<!--                <btn v-if="(curr_pagination_sec < pagination_sec_count + 1) && !notEnoughPages"-->
<!--                     class="fa fa-chevron-right" size="sm"-->
<!--                     @click="nextPaginationSection"/>-->
                <div v-if="(curr_pagination_sec < pagination_sec_count + 1) && !notEnoughPages" class="d-inline cursor-pointer font-inter-medium mr-2"
                     @click="nextPaginationSection">Next
                </div>
            </div>

        </div>
    </div>
</template>

<script>

export default {
    name: 'CustomPagination',

    props: {
        lastPage: {
            type: Number
        },

        currentPage: {
            type: Number
        },

        onEachSide: {
            type: Number,
            default () {
                return 2;
            }
        }

    },

    computed: {
        notEnoughPages () {
            return this.lastPage < (this.onEachSide * 2) + 4;
        },

        stripPages () {
            return [...Array(this.last_page).keys()];
        }
    },

    mounted () {
        if (this.lastPage < 5) {
            this.pagination_sec_count = 1;
        } else {
            // const secValue = this.lastPage / 5;
            // if (secValue >)
        }
    },

    data () {
        return {
            current_page: this.currentPage,
            last_page: this.lastPage,

            pagination_sec_count: 1,
            perfect_pagination: false,

            curr_pagination_sec: 1,

            pagination_lower_limit: null,
            pagination_upper_limit: null
        };
    },
    watch: {

        curr_pagination_sec: {
            handler () {
                this.pagination_lower_limit = (this.curr_pagination_sec - 1) * 5;
                this.pagination_upper_limit = this.curr_pagination_sec * 5;
            }
        },

        last_page: {
            handler () {
                console.log('last page', this.last_page);
                if (this.last_page > 1 && this.last_page < 6) {
                    this.pagination_sec_count = 1;
                } else if (this.last_page % 5 === 0) {
                    this.pagination_sec_count = parseInt(this.last_page / 5);
                    this.perfect_pagination = true;
                } else if (this.last_page % 5 !== 0) {
                    this.pagination_sec_count = parseInt(this.last_page / 5);
                    // alert('not perfect pag');
                    this.perfect_pagination = false;
                }
            }
        }
    },

    methods: {
        nextPaginationSection () {
            console.log('next pagination section');
            if (this.curr_pagination_sec < this.pagination_sec_count && this.perfect_pagination) {
                this.curr_pagination_sec += 1;
            } else if (!this.perfect_pagination && this.curr_pagination_sec < this.pagination_sec_count + 1) {
                this.curr_pagination_sec += 1;
            }
        },

        previousPaginationSection () {
            if (this.curr_pagination_sec > 1) {
                this.curr_pagination_sec -= 1;
            }
        },

        nextPage () {
            if (this.current_page !== this.last_page) {
                this.current_page = this.current_page + 1;
                // this.loadDetails();
                console.log('next page');
                this.$emit('nextPage', this.current_page);
            }
        },

        previousPage () {
            if (this.current_page !== 1) {
                this.current_page = this.current_page - 1;
                this.loadDetails();
                this.$emit('previousPage', this.current_page);
            }
        },

        gotoPage (pageNumber) {
            console.log('pageNumber : ', pageNumber);
            this.$emit('gotoPage', pageNumber);
        }
    }
};
</script>

<style scoped lang="scss">
.active-page {
    background-color: var(--color-secondary);
    color: var(--color-white);
}

.pagination-btn {
    padding: var(--spacer-3) 0.8rem;
}

</style>
