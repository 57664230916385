<template>
    <div class="training-center-bg py-6" style="background-color: black;">
        <div class="container">
            <div class="px-lg-7">
                <div class="row">
                    <div class="col-12">
                        <div class="text-white text-center">
                            <h2 class="fs-lg-1 font-lato-semi-bold text-uppercase">Training Centers</h2>
                            <h3 class="font-jost-semi fs-lg-10 mb-2 mb-lg-3">We Provide Multiple Training Centers</h3>
<!--                            <p class="mb-5 fs-lg&#45;&#45;2 font-lato-medium text-white-1 px-lg-7 text-center">-->
<!--                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque debitis dignissimos-->
<!--                                enim eum ex, incidunt magni quae quo tempore veniam. Lorem ipsum dolor sit amet. Lorem-->
<!--                                ipsum dolor sit amet.-->
<!--                            </p>-->
                        </div>
                    </div>
                    <div @click="viewDetails(i.id)" class="col-4 col-lg mb-3"
                         v-for="(i, index) in details" :key="index">
                        <training-center-item :img="i"></training-center-item>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TrainingCenterItem from '../../components/home/TrainingCenterItem';

export default {
    name: 'TrainingCenters',
    props: {
        details: { type: Array, default: null }
    },
    components: { TrainingCenterItem },
    methods: {
        viewDetails (id) {
            this.$router.push('/training-centers/#' + id);
        }
    }
};
</script>

<style scoped lang="scss">
.training-center-bg {
    background: url("../../assets/web/landing/training-areas-bg.jpg");
    background-size: cover;
}

.training-center-item {
    &:nth-child(5) {
        margin-left: 3.75rem !important;
        @media (min-width: 992px) {
            margin-left: 0 !important;
        }
        @media (min-width: 768px) and (max-width: 1024px) {
            margin-left: 7.8rem !important;
        }
    }

    &:nth-child(6) {
        margin-left: 1.5rem !important;
        @media (min-width: 992px) {
            margin-left: 0 !important;
        }
        @media (min-width: 768px) and (max-width: 1024px) {
            margin-left: 0.75rem !important;
        }
    }

}
</style>
