<template>
    <vue-table-card title="News" :show-search-box="false" :fields="fields" :url="listUrl" :per-page="10" ref="table">
        <template #buttons>
            <btn color="success" icon="fa fa-plus" size="sm" @click="$refs.addModal.show()" text="Add"/>
        </template>

        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <btn size="xs" text="View" @click="viewDetails(rowData.id)"/>
                <delete-btn @click="setDelete(rowData)"/>
                <btn :loading="loading && loading_id===rowData.id"
                     loading-text="Publishing..." v-if="!rowData.publishing_date" size="xs"
                     text="Publish" @click="publishNews(rowData.id)"/>
                <btn :loading="loading && loading_id===rowData.id" loading-text="Unpublishing..." v-else size="xs"
                     text="Unpublish" @click="publishNews(rowData.id)"/>
            </div>
        </template>
        <template #is_enabled="{rowData}">
            <p v-if="rowData.is_enabled">Yes</p>
            <p v-else>No</p>
        </template>
        <template #publishing_date="{rowData}">
            <p v-if="rowData.publishing_date">{{ rowData.publishing_date }}</p>
            <p v-else>Not Published</p>
        </template>

        <template #footer>
            <modal title="Add News" ref="addModal" width="40r" header-color="primary" no-close-on-backdrop>
                <add-news @success="formSuccess"/>
            </modal>

            <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                <p>You are about to delete the news <b v-html="deletingItem && deletingItem.name"></b>. Are you sure ?
                </p>

                <template #loading>
                    <loading-animation/>
                    Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                </template>
            </delete-modal>
        </template>

    </vue-table-card>
</template>

<script>
import urls from '@/data/urls';
import AddNews from './AddNews';
import axios from 'secure-axios';

export default {
    name: 'News',
    components: { AddNews },
    data () {
        return {
            listUrl: urls.admin.news.list,
            addUrl: urls.admin.news.addEdit,
            deleteUrl: urls.admin.news.delete,
            loading_id: '',
            loading: false,
            editingItem: null,
            deletingItem: null,
            fields: [
                {
                    name: 'title',
                    titleClass: 'w-30r',
                    sortField: 'title',
                    title: 'Title'
                },
                {
                    name: 'category.category_name',
                    sortField: 'category.category_name',
                    title: 'Category'
                },
                {
                    name: 'created_at',
                    sortField: 'created_at',
                    title: 'Created At'
                },
                {
                    name: '__slot:publishing_date',
                    title: 'Publishing Date'
                },
                {
                    name: '__slot:is_enabled',
                    title: 'Enabled'
                },
                {
                    name: '__slot:actions',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right'
                }
            ]
        };
    },
    methods: {
        async publishNews (id) {
            this.loading_id = id;
            this.loading = true;
            const response = await axios.form(urls.admin.news.publish, { id: id });
            const json = response.data;
            if (json.error === false) {
                this.$notify(json.msg, 'Success', {
                    type: 'success'
                });
                const refs = this.$refs;
                refs.table.refreshTable();
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.loading = false;
        },
        viewDetails (id) {
            this.$router.push('/admin/news/' + id + '/details/');
        },
        formSuccess () {
            const refs = this.$refs;
            refs.addModal.close();
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        }
    }
};
</script>

<style scoped>

</style>
