<template>
    <data-loading-component v-if="dataLoading"/>
    <div v-else>
        <div v-if="details">
            <training-center-banner :details="details.banners"></training-center-banner>
            <div v-if="details.training_centers.length!==0">
                <div v-for="(item, index) in details.training_centers" :key="index">
                    <training-center01 @viewLocation="viewLocation" :id="'item' + item.id" v-if="getCard(index)"
                                       :details="item"></training-center01>
                    <training-center02 @viewLocation="viewLocation" :id="'item' + item.id" v-else
                                       :details="item"></training-center02>
<!--                    Added for testing-->
<!--                    <training-center01 @viewLocation="viewLocation" id="item12" v-if="getCard(index)"-->
<!--                                       :details="item"></training-center01>-->
                </div>
            </div>
            <div v-else class="text-center p-5">
                <p>No training centers available currently.</p>
            </div>
            <training-center-map v-if="details.training_centers.length!==0" :details="details.training_centers"></training-center-map>
        </div>
    </div>
</template>

<script>
import TrainingCenterBanner from '../components/training-center/TrainingCenterBanner';
import TrainingCenter01 from '../components/training-center/TrainingCenter01';
import TrainingCenter02 from '../components/training-center/TrainingCenter02';
import TrainingCenterMap from '../components/training-center/TrainingCenterMap';
import axios from 'secure-axios';
import urls from '../data/urls';
import DataLoadingComponent from '../components/ui/DataLoadingComponent';

export default {
    name: 'TrainingCentersPage',
    components: {
        DataLoadingComponent,
        TrainingCenterMap,
        TrainingCenter02,
        TrainingCenterBanner,
        TrainingCenter01
    },

    data () {
        return {
            details: '',
            dataLoading: false
        };
    },

    beforeMount () {
        this.loadDetails();
    },

    methods: {
        viewLocation (i) {
            window.open('https://maps.google.com/maps?q=' + i.latitude + ',' + i.longitude + '&hl=es;z=14&amp;output=embed');
        },
        // commented - similar logic is used in scrollBehavior function in router/index.js
        // goTO () {
        //     if (this.$route.hash) {
        //         window.location.href = this.$route.hash;
        //         this.scrollToTop();
        //     }
        // },
        // scrollToTop () {
        //     window.scrollBy(0, -150);
        // },
        getCard (index) {
            return index % 2 === 0;
        },
        async loadDetails () {
            this.dataLoading = true;
            const response = await axios.get(urls.website.trainingCenter);
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
                // setTimeout(this.goTO, 100);
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.dataLoading = false;
            this.$emit('loadingSuccess');
        }
    }
};
</script>

<style scoped>

</style>
