<template>
    <div class="px-lg-5 py-3">
        <b-form class="row" :save-url="registerUrl" @success="formSuccess" v-slot="{model, loading}"
                :save-params="{course_register : details.id}" :initial-data="editingItem">
            <div class="col-12 col-md-6">
                <validated-input size="sm" placeholder="Name" name="Name"
                                 id="name" v-model="model.name" :disabled="loading">
                </validated-input>
            </div>
            <div class="col-12 col-md-6">
                <validated-vue-select size="sm" name="Gender" :disabled="loading"
                                      :options="genderOptions" v-model="model.gender"
                                      placeholder="Gender"></validated-vue-select>
            </div>
            <div class="col-12 col-md-6">
                <validated-date-picker value-type="YYYY-MM-DD" format="DD-MM-YYYY" size="sm" placeholder="Date Of Birth"
                                       v-model="model.dob" :disabled="loading" name="Dob"
                                       :disabled-date="disabledDateAfterToday">
                </validated-date-picker>
            </div>
            <div class="col-12 col-md-6">
                <validated-input size="sm" placeholder="Phone number"
                                 name="Phone number" type="number" id="Phone"
                                 v-model="model.phone_number" :rules="{max: 10, min: 10}"
                                 :disabled="loading">
                </validated-input>
            </div>
            <div class="col-12 col-md-6">
                <validated-input size="sm" placeholder="Email Id"
                                 name="Email" type="email" v-model="model.email"
                                 :disabled="loading">
                </validated-input>
            </div>
            <div class="col-12 col-md-6">
                <validated-input size="sm" placeholder="Aadhar Number"
                                 name="Adhar number" type="number" id="Adhar number"
                                 v-model="model.adhar_number" :rules="{max: 12, min: 12}"
                                 :disabled="loading">
                </validated-input>
            </div>
            <div class="col-12 col-md-6">
                <validated-vue-select size="sm" name="Marital Status"
                                      :disabled="loading" :options="maritalStatusOptions"
                                      v-model="model.marital_status"
                                      placeholder="Marital Status"></validated-vue-select>
            </div>
            <div class="col-12 col-md-6">
                <validated-input size="sm" placeholder="House/ Flat No./ Building/Apartment"
                                 name="Address" id="Address"
                                 v-model="model.address" :disabled="loading">
                </validated-input>
            </div>
            <div class="col-12 col-md-6">
                <validated-input size="sm" placeholder="Area/Street/Village"
                                 name="Area" id="Area" v-model="model.area"
                                 :disabled="loading">
                </validated-input>
            </div>
            <div class="col-12 col-md-6">
                <validated-input size="sm" placeholder="Town/City"
                                 name="City" id="City" v-model="model.city"
                                 :disabled="loading">
                </validated-input>
            </div>
            <div class="col-12 col-md-6">
                <validated-ajax-vue-select size="sm" name="State"
                                           :disabled="loading" :url="stateOptions"
                                           v-model="model.state" placeholder="Select State"/>
            </div>
            <div class="col-12 col-md-6">
                <validated-input size="sm" name="Pincode" placeholder="Pincode"
                                 v-model="model.pincode" :disabled="loading"
                                 type="number">
                </validated-input>
            </div>
            <div class="col-12">
                <div class="fl-x fl-j-c">
                    <div class="btn-group pt-5 pb-5">
                        <btn @click="closeModal" type="button" border-radius="0" size="md" class="px-5" text="Close"
                             design="basic-b"></btn>
                        <btn :loading="loading" loading-text="Updating..." border-radius="0" size="md" class="px-5"
                             text="Update User" v-if="editingItem && editingItem.id"></btn>
                        <btn :loading="loading" loading-text="Submitting..." border-radius="0" size="md"
                             class="px-5"
                             text="Add User" v-else></btn>
                    </div>
                </div>
            </div>
        </b-form>
    </div>
</template>

<script>
import urls from '../../../data/urls';

export default {
    name: 'UserInformationForm',
    props: {
        details: {
            type: Object,
            default: null
        },
        editingItem: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            stateOptions: urls.masterData.State.stateOptions,
            registerUrl: urls.website.course.coursesOrganizationUserCreate,
            genderOptions: [
                { value: 'M', label: 'Male' },
                { value: 'F', label: 'Female' },
                { value: 'O', label: 'Others' }
            ],
            maritalStatusOptions: [
                { label: 'Married', value: 'Married' },
                { label: 'Unmarried', value: 'Unmarried' },
                { label: 'Divorced', value: 'Divorced' }
            ]
        };
    },
    methods: {
        disabledDateAfterToday (date) {
            const today = new Date();
            return date > today;
        },
        closeModal () {
            this.$emit('close');
        },
        formSuccess (response) {
            this.closeModal();
            if (this.editingItem && this.editingItem.id) {
                this.$notify('User Updated Successfully', 'Success', {
                    type: 'success'
                });
            } else {
                this.$notify('User Added Successfully', 'Success', {
                    type: 'success'
                });
            }
            this.$emit('success', response.data);
        }
    }
};
</script>

<style scoped>

</style>
