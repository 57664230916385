<template>
    <div class="card-one" :id="id">
        <div class="container py-lg-6">
            <div class="row">

                <div class="col-12 col-lg-7 p-0">
                    <div class="d-lg-none">
                        <section-heading :title="details.name" bottom-tag-align="left" class="text-left mt-lg-0 mt-4 mt-md-5 "
                                         align="left">
                        </section-heading>
                        <H2 class="font-jost-medium fs-5 mb-2 text-center-md-res">{{ details.location }},
                            {{ details.district_name }}, {{ details.state_name }}</H2>

                    </div>
                    <div class="parent mt-42">
                        <img alt="" class="image1 pos-r" :src="details.thumbnail1">
                        <img alt="" class="image2" :src="details.thumbnail2">
                    </div>
                </div>
                <div class="col-12 col-lg-5 ">
                    <div class="sec-1">

                        <section-heading :title="details.name" bottom-tag-align="left"
                                         class="text-left d-lg-content d-md-none mt-lg-0  font-jost-regular "
                                         align="left">
                        </section-heading>
                        <H2 class="font-jost-medium mt-415 d-md-none fs-5 d-lg-content">{{ details.location }},
                            {{ details.district_name }}, {{ details.state_name }}</H2>
                        <p class="font-lato-medium fs-1 mt-4 margin-tp-md-6">{{ details.content1 }}</p>
                        <p class="font-lato-medium fs-1">{{ details.content2 }}</p>
                        <div class="mt-5">
<!--                            <button data-v-2b18a82e="" type="submit"-->
<!--                                    class="lego-btn  btn-color-green round-0 bs-1  round-nav-btn-r outline-primary map-icon" design="basic-b">-->
<!--                                &lt;!&ndash;&ndash;&gt;<span>View Location</span>&lt;!&ndash;&ndash;&gt;</button><br>-->
                            <btn @click="viewLocation(details)" border-radius="0" text="View Location" class="map-icon" design="basic-b"></btn><br>

                            <btn border-radius="0" @click="$router.push('/course-offered/#' + details.id)" class="px-3 mt-3 mb-4 mb-md-5"
                                 text="Courses Details"></btn>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import SectionHeading from '@components/ui/SectionHeading';

export default {
    name: 'TrainingCenter01',
    props: {
        details: { type: Object, default: null },
        id: { type: String, default: '' }
    },
    components: { SectionHeading },
    methods: {
        viewLocation (item) {
            this.$emit('viewLocation', item);
        }
    }
};
</script>
<style scoped>
.card-section {
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    color: black;
    background-position: center;
    background-size: cover;
}

.sec-1 {
    padding: 4px;
    align-items: center;
}

.parent {
    position: relative;
    top: 0;
    left: 0;
}

.image1 {
    position: relative;
    top: 14px;
    left: 1px;
    width: 61%;
    height: 274px;
    z-index: 1;
}

.image2 {
    position: absolute;
    top: 114px;
    width: 29%;
    right: 120px;
    height: 228px;
}

.card-one {
    /*padding: 4rem;*/
    /*padding-left: 7rem;*/

}

.parent {
    padding-right: 0;
}

.dg-lg-none {
    display: none;
}

@media (max-width: 750px) {
    .d-lg-none {
        display: contents;
        text-align: center;

    }

    .d-lg-content {
        display: none;
    }

    .card-one {
        padding: 1rem;
        padding-left: 0;
        padding-right: 0;
        marging: 0;
    }

    .image1 {
        top: 0;
        left: 0;
        width: 100%;
        height: 254px;
        background-position: center 100%;
        padding: 0;
        background-size: contain;

    }

    .image2 {
        left: 0px;
        width: 0%;
        height: 0px;
        top: 0px;
        width: 0%;
        right: 0px;
        z-index: 0;

    }

    .sec-1 {
        padding: 1rem;
        text-align: center;
    }

    .resp-p0 {
        paddinng: 0px !important;
    }

}
@media (min-width: 768px) and (max-width: 1024px) {
    .margin-tp-md-6{
        margin-top:6rem !important;
    }
}
</style>
