<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}">
        <div class="row">
            <div class="col-12">
                <validated-input label="Heading" v-model="model.heading" class="required-field"
                                 :rules="{required: true, max:50}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <file-input class="c-file-input" ref="file" label="Image 1" v-model="model.image1"
                            :disabled="loading" name="Image1">
                </file-input>
                <image-info :height="690" :width="1100"/>
            </div>
            <div class="col-12">
                <file-input class="c-file-input" ref="file" label="Image 2" v-model="model.image2"
                            :disabled="loading" name="Image2">
                </file-input>
                <image-info :height="341" :width="425"/>
            </div>
            <div class="col-12">
                <validated-text-area label="Description 1" v-model="model.description1" class="required-field"
                                     :rules="{required: true, max:300}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <validated-text-area label="Description 2" v-model="model.description2" class="required-field"
                                     :rules="{required: true, max:300}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <file-input class="c-file-input" ref="file" label="Signature" v-model="model.signature"
                            :disabled="loading" name="Signature">
                </file-input>
                <image-info :height="84" :width="223"/>
            </div>
            <div class="col-6">
                <validated-input label="Name" v-model="model.name" class="required-field"
                                 :rules="{required: true, max: 50}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-input label="Role" v-model="model.role" class="required-field"
                                 :rules="{required: true, max: 50}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-input type="number" label="Order" v-model="model.order"
                                 :rules="{required: false, min_value:1}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-checkbox label="Enabled" v-model="model.is_enabled"
                                    :rules="{required: false}" :disabled="loading"/>
            </div>
            <div class="col-12 text-right">
                <btn class="trn" text="Save" loading-text="Saving..." size="sm" :disabled="loading" :loading="loading"/>
            </div>
        </div>
    </b-form>
</template>

<script>

import urls from '../../../../data/urls';

export default {
    name: 'AddAboutUs',

    data () {
        return {
            addUrl: urls.admin.aboutUs.addEdit
        };
    },

    methods: {
        formSuccess () {
            this.$notify('Successfully Added Item..!', 'Success',
                {
                    type: 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
