<template>
    <div class="c-fl-j-sb">
        <div v-if="details">
            <h4 class="font-jost-semi fs-lg-2 mb-4"><span class="mr-3">01</span> Modern Trends In The Cryopreservation
                Of Bovine Semen</h4>
            <div class="row w-100 ml-lg-5 fs-lg-1 font-lato-medium">
                <div class="col-12 col-lg-6 pr-5 pos-r">
                    <div class="line-right"></div>
                    <ul class="list-unstyled ul-course-detail">
                        <li class="mb-3"><span class="mw-9re">Course Fee</span> : <span
                            class="fs-4 font-lato-semi-bold ml-3">{{ details.course_fee }}</span>/-
                        </li>
                        <li><span>Seat Filled</span> : <span class="ml-3">{{ details.seats_filled }} Students</span>
                        </li>
                        <li><span>Location</span> : <span class="ml-3">{{ details.location }}</span></li>
                        <li><span>Duration</span> : <span class="ml-3">{{ details.duration }}</span></li>
                        <li><span>Date</span> : <span class="ml-3">{{ details.duration_formatted }}</span></li>
                    </ul>
                </div>
                <div class="col-12 col-lg-6">
                    <ul class="list-unstyled ul-course-detail-1 pl-lg-4" v-if="cardType === 'ind'">
                        <li><span>Transaction Id</span> : <span class="ml-3">{{ details.transaction_id }}</span></li>
                        <li><span>Transaction Status</span> : <span class="ml-3">{{ details.transaction_status }}</span>
                        </li>
                        <li><span>Transaction Date</span> : <span class="ml-3">{{ details.transaction_date }}</span>
                        </li>
                    </ul>
                    <ul class="list-unstyled ul-course-detail-1 pl-lg-4" v-if="cardType === 'org'">
                        <li>
                            <span>Number of Participants</span> :
                            <span class="ml-3" @click="$refs.participantsPopup.show()">
                                {{ details.applicants_count }} <a class="text-primary ml-2" href="#">View</a>
                            </span>
                        </li>
                        <li><span>Reference ID</span> : <span class="ml-3">{{ details.transaction_id }}</span></li>
                        <li><span>Registration Date</span> : <span class="ml-3">{{ details.transaction_date }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="mt-3 mt-lg-0">
            <btn @click="viewDetails(details.id)" text="Course Info." class="px-4 bs-5 font-inter-medium c-bs-square"></btn>
        </div>
        <!--        Modals-->
        <modal class="c-modal no-close header-primary" :no-close-on-backdrop="true"
               :title="'Participants - ' + details.organization"
               ref="participantsPopup" width="40r" header-color="">
            <div class="pb-3">
                <h2 class="font-jost-semi text-center fs-lg-2 mb-5 mt-3">
                    Modern Trends In The Cryopreservation <br>
                    Of Bovine Semen
                </h2>
                <div class="row pb-2">
                    <div class="col-12">
                        <div v-if="details.applicants && details.applicants.length!==0" class="fl-y fl-a-c font-lato-semi-bold">
                            <div v-for="(i, index) in details.applicants" :key="index" class="p-2 bg-4 mb-2 w-90p row">
                                <div class="col-md-2">{{ index + 1 }}.</div>
                                <div class="col-md-4">{{ i.name }}</div>
                                <div class="col-md-6">{{ i.email }}</div>
                            </div>
                        </div>
                        <p v-if="details.applicants.length===0" class="text-center mt-3">No participants.</p>
                    </div>
                </div>
                <div class="mt-5 fl-y fl-a-c ">
                    <btn text="Close" @click="$refs.participantsPopup.close()" size="md" design="basic-b"
                         border-radius="0" class="px-5 font-lato-medium"></btn>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
export default {
    name: 'CourseItem',
    props: {
        details: {
            type: Object,
            default: null
        },
        cardType: {
            type: String,
            default: 'ind'
        }
    },
    methods: {
        viewDetails (id) {
            this.$router.push('/course-offered/' + id + '/details/');
        }
    }
};
</script>

<style scoped lang="scss">
.ul-course-detail {
    li {
        margin-bottom: var(--spacer-3) !important;
        font-family: lato-medium;
        span {
            &:first-child {
                display: inline-block;
                min-width: 6rem !important;
                @media (min-width: 992px) {
                    min-width: 9rem !important;
                }
            }
        }
    }
}

.ul-course-detail-1 {
    li {
        margin-bottom: var(--spacer-3) !important;

        span {
            &:first-child {
                display: inline-block;
                min-width: 12rem !important;
            }
        }
    }
}

.mw-9re {
    display: inline-block;
    min-width: 9rem !important;
}
</style>
