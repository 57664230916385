<template>
    <data-loading-component v-if="dataLoading"/>
    <div class="of-h" v-else>
        <div v-if="details">
            <contact-banner :details="details.banners"></contact-banner>
            <div class="container pt-5">
                <contact-form :details="details"></contact-form>
            </div>
            <training-center-map class="pt-lg-4 bg-7" :details="details.training_centers"></training-center-map>
        </div>
    </div>

</template>

<script>
import ContactBanner from '../components/contact-us/Contact-Banner';
import ContactForm from '../components/contact-us/ContactForm';
import axios from 'secure-axios';
import urls from '../data/urls';
import TrainingCenterMap from '../components/training-center/TrainingCenterMap';
import DataLoadingComponent from '../components/ui/DataLoadingComponent';

export default {
    name: 'ContactUs',
    components: { DataLoadingComponent, TrainingCenterMap, ContactBanner, ContactForm },

    data () {
        return {
            details: '',
            dataLoading: false
        };
    },

    beforeMount () {
        this.loadDetails();
    },

    methods: {
        async loadDetails () {
            this.dataLoading = true;
            const response = await axios.get(urls.website.contactUs);
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.dataLoading = false;
            this.$emit('loadingSuccess');
        }
    }
};
</script>

<style scoped>

</style>
