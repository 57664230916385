<template>
    <div class="Contact-form mh32">
        <div class="row pb-lg-9 wr">
            <div class="col-12 col-lg-5">
                <div class="s-1">
                    <section-heading title="CONTACT US" bottom-tag-align="left" class="text-left mb-4 mb-lg-5" align="left">
                    </section-heading>
                    <h3 class="font-jost-semi-bold mb-4  line-h2 fs-4  fs-lg-7">{{ details.contact_us.heading }}</h3>
                    <h3 v-if="!details.contact_us.heading" class="mt-42 font-jost-semi-bold fs-lg-7 mb-4 fs-4">Are You
                        Interested In
                        Our Training <br>Programs? Contact Us</h3>

<!--                    <p class="font-lato-medium mb-4 lh-1p7  f-1">{{ details.contact_us.description }}</p>-->
<!--                    <p v-if="!details.contact_us.description" class="font-lato-medium"> "Lorem ipsum dolor sit amet,-->
<!--                        consectetur adipisicing elit.-->
<!--                        Ad amet autem cumque dignissimos eaque-->
<!--                        m inis provident quae-->
<!--                        quasi quisquam quod repellendus saepe sapiente sequi soluta ut. Ab alias amet at, deleniti-->
<!--                        ducimus laboriosam laudantium nam placeat vero voluptate. <br><br>-->
<!--                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad amet-->
<!--                        autem cumque dignissimos eaque"-->

<!--                    </p>-->

<!--                    <p  class="font-lato-bold fs-1 mb-1">-->
<!--                        {{ details.contact_us.name }}-->
<!--                    </p>-->
<!--                    <p class="font-lato-regular mb-5 fs-1">{{ details.contact_us.role }}</p>-->

                </div>
            </div>
            <div class="col-12 col-lg-7">
                <div class="c-parent  px-2 px-lg-0">
                    <div class="card bs-4 border-r-0 min-w-27re">
                        <section id="contact-form" class="py-3">
                            <h2 class="font-lato-bold fs-6 pl-3 ">Fill Out For Contact</h2>
                            <p class="font-lato-medium lh-2 fs-1 mt-3 mb-4 pl-3">Fill the contact form and get immidiate assistance
                                <br>
                                from our educational consultant.
                            </p>
                            <b-form class="" :save-url="sendContactDetails" @success="formSuccess" @failure="formError"
                                    v-slot="{model, loading}">

                                    <validated-input size="m" placeholder="Your Name" border-radius="0"
                                                     v-model="model.name" :disabled="loading" :rules="{required: true}"
                                                     class="c-contact-input font-lato-regular mb-3" required/>

                                    <validated-input class="c-contact-input font-lato-regular mb-3" size="m" type="email"
                                                     placeholder="Email Address"
                                                     :disabled="loading" :rules="{required: true}"
                                                     border-radius="0" name="email" id="email" v-model="model.email">
                                    </validated-input>

                                    <validated-input class="c-contact-input font-lato-regular mb-3" size="m" type="number"
                                                     placeholder="Phone Number"
                                                     border-radius="0" name="phone" v-model="model.phone_number"
                                                     id="phonenumber" :disabled="loading"
                                                     :rules="{required: true, max: 10, min: 10}">
                                    </validated-input>

                                    <validated-ajax-vue-select size="m" border-radius="0"
                                                               class="c-select-field mb-3 c-input-select-s font-lato-regular"
                                                               aria-placeholder="Select Program"
                                                               :url="courseOptions" :rules="{required: true}"
                                                               v-model="model.training_program" :disabled="loading"
                                                               placeholder="Training Program"></validated-ajax-vue-select>

                                    <validated-ajax-vue-select size="m" border-radius="0"
                                                               class="mb-3 c-select-field c-input-select-s font-lato-regular"
                                                               aria-placeholder="Select Center"
                                                               :url="trainingCenterOptions" :rules="{required: true}"
                                                               v-model="model.training_center" :disabled="loading"
                                                               placeholder="Training center"/>

                                <div class="">
                                    <validated-text-area size="m" placeholder="Message" border-radius="0"
                                                         class=" mb-3 text-area-c font-lato-regular"
                                                         name="message" v-model="model.message"
                                                         :rules="{required: true}"
                                                         :disabled="loading"></validated-text-area>
                                </div>
                                <btn text="SEND NOW" :loading="loading" loading-text="Sending..." block
                                     border-radius="0" class=" font-lato-regular btn-22"></btn>
                            </b-form>
                        </section>

                    </div>
                </div>
            </div>

            <div class="">
            </div>
        </div>
    </div>
</template>
<script>
import SectionHeading from '@components/ui/SectionHeading';
import urls from '../../data/urls';

export default {
    name: 'ContactForm',
    props: {
        details: { type: Object, default: null }
    },
    components: { SectionHeading },
    data () {
        return {
            model: {},
            courseOptions: urls.admin.course.vueSelect,
            trainingCenterOptions: urls.admin.trainingCenter.vueSelect,
            sendContactDetails: urls.admin.contactUs.addEdit
        };
    },
    methods: {
        formSuccess () {
            this.$notify('Send Successfully', 'Success', {
                type: 'success'
            });
            this.$router.go();
        },
        formError () {
            this.$notify('Failed to send, Please try again later', 'Failed', {
                type: 'danger'
            });
            this.$router.go();
        }
    }
};

</script>
<style scoped lang="scss">
textarea.control, textarea.form-control {
    min-height: 7rem;
    height: auto;
}
.num-hide{
  -webkit-appearance: none;

}
.wr{
    height:500px;
}
.c-line-height{
    line-height:1.6rem !important;
}
.mb-3 {
    margin-bottom: .5rem;
}
.line-h2 {
    line-height: 2.3rem;
}
.lh-1p7{
    line-height: 1.6rem;
}
.c-parent {
    position: relative;
    @media (min-width: 992px) {
        margin-left: 0;
        position: absolute;
        top: -12rem;
        margin-left: 100px !important;
    }
}

#contact-form .form-group {

    margin-bottom: 20px;

    #contact-form label {
        /*display: block;*/
        margin-bottom: 5px;
    }

    #contact-form input,
    #contact-form textarea {
        width: 100%;
        padding: 7rem !important;

    }

    #contact-form input:focus,
    #contact-form textarea:focus {
        outline: none;
    }

    #contact-form textarea {
        height: 7rem !important;
    }

    //.Contact-form {
    //    padding: 5rem;
    //    padding-top:3rem;
    //    padding-bottom: 0;
    //}
    .s-1 {

        padding: 1.3rem;

    }

    //.c-parent {
    //    position: absolute;
    //    top: -12rem;
    //    margin-left: 100px !important;
    //    @media (min-width: 992px) {
    //        margin-left: 0;
    //    }
    //}

    .map {
        text-align: center;
    }

    //.b{
    //    padding: 3rem;
    //    padding-top:0;
    //}
    .mappp {
        background-image: url('../../assets/web/contact us/map.png');
        height: 45px;
        width: 45px;
        background-size: contain;
        background-repeat: no-repeat;

    }

    .contacti {
        background-image: url('../../assets/web/contact us/phone.png');
        height: 55px;
        width: 55px;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .timei {
        background-image: url('../../assets/web/contact us/timee.png');
        height: 55px;
        width: 55px;
        background-size: contain;
        background-repeat: no-repeat;

    }

    .s-1 {
        margin-right: 0px;
        padding: 2rem;

    }

    h6 {
        color: black;

    }
    .mh32{
        min-height:32rem!important;
    }

    @media(max-width: 750px) {
        //.c-parent {
        //    position: unset !important;
        //    top: 0px;
        //    margin-left: 0px !important;
        //}
        .c-parent-2 {
            position: relative !important;
            top: 0px;
            margin-left: 0px !important;
        }
      .c-parent {
        position: relative !important;
        top: 0px;
        margin-left: 0px !important;
      }

        .mappp {
            background-image: url('../../assets/web/contact us/map.png');
            height: 45px;
            width: 45px;
            background-size: contain;
            background-repeat: no-repeat;
            text-align: center;
            background-position: center;

        }
        .contacti {
            background-image: url('../../assets/web/contact us/phone.png');
            height: 35px;
            width: 30px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
        .timei {
            background-image: url('../../assets/web/contact us/timee.png');
            height: 35px;
            width: 30px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;

        }
        .Contact-form {
            padding: 0;
            min-height: 32rem!important;

        }

        .s-1 {
            padding: 2rem;
            text-align: center;

        }
        .card {
            margin-left: 0;
        }
        .ic {
            margin-right: 0px !important;
            margin-left: 0px !important;
            /*padding: 0px !important;*/
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
        .pb {
            padding-top: 4px !important;
        }
    }

}
</style>
